// import logo from './logo.svg';
import React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import PhotoSection from './PhotoSection';
import BusinessInfo from './BusinessInfo';
import GoogleMapEmbed from './mapsgoog'; // Ensure the import path is correct
//  import LogoPage from './LogoPage'; // Import the LogoPage component
import FWProductsPage from './FWProductsPage';
import XProductsPage from './XProductsPage';
import CProductsPage from './CProductsPage';
import MProductsPage from './MProductsPage';
import HProductsPage from './HProductsPage';
import './App.css';
import Navbar from './navbar'; // Import the Navbar component
import LinkPreviews from './LinkPreviews'; // Import the new component
import CommercialPopup from './CommercialPopup'; // Import your CommercialPopup component


function App() {

   // State to manage whether the commercial is shown or not
   const [showCommercial, setShowCommercial] = useState(true);

   // Function to handle closing the commercial popup
   const handleCloseCommercial = () => {
     setShowCommercial(false);
   };
 
   // Example images for the commercial (adjust the paths as per your project structure)
   const commercialImages = [
     'commercial1.webp',
     'commercial2.webp',
      'commercial3.webp',
      'commercial4.webp',
      'commercial5.webp',
      'commercial6.webp',
      'commercial7.webp',
      'commercial8.webp',
      'commercial9.webp',
      
      
     
   ];

  return (
  <Router>
    <div className="App">

      {/* Show the commercial popup if showCommercial is true */}
      {showCommercial && (
          <CommercialPopup images={commercialImages} onClose={handleCloseCommercial} />
        )}
        
    <Header />
    <Navbar /> {/* Use the Navbar component */}
      
        <Routes>
          <Route path="/" element={
            
            <div>
              <div id="links">
                <LinkPreviews /> {/* Add the LinkPreviews component here */}
              </div>
              <div id="photo-section">
                <PhotoSection />
              </div>
              <div id="business-info">
                <BusinessInfo />
              </div>
              <div id="map">
              <GoogleMapEmbed />
              </div>
              
            </div>
          } />
          
          <Route path="/FWproducts" element={<FWProductsPage />} />
          <Route path="/Cproducts" element={<CProductsPage />} /> 
          <Route path="/Xproducts" element={<XProductsPage />} />  
          <Route path="/Mproducts" element={<MProductsPage />} /> 
          <Route path="/Hproducts" element={<HProductsPage />} />    
          {/* <Route path="/logo" element={<LogoPage />} /> Add the new logo page route */}
        
        </Routes>
      </div>
    </Router>
  );
};


export default App;


